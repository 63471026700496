import React, { useEffect, useState } from "react";
import Flag from "react-flagkit";
import { useTranslation } from "react-i18next";
import { FaAngleDown } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const location = useLocation();

  const [t, i18n] = useTranslation("global");
  const handleChangeLanguge = (e) => {
    i18n.changeLanguage(e);
  };

  useEffect(() => {
    if (i18n.language == "en") {
      document.documentElement.dir = "ltr";
    } else {
      document.documentElement.dir = "rtl";
    }
  }, [i18n.language]);

  useEffect(() => {
    getIp();
  }, []);

  async function getIp() {
    const response = await fetch("https://geolocation-db.com/json/");
    const data = await response.json();
    if (data.country_code === "IR") {
      i18n.changeLanguage("fa");
    } else if (
      data?.country_code === "SA" ||
      data?.country_code === "IQ" ||
      data?.country_code === "OM" ||
      data?.country_code === "AE"
    ) {
      i18n.changeLanguage("ar");
    } else {
      i18n.changeLanguage("en");
    }
  }
  return (
    <div dir="ltr" class="container-fluid nav-bar bg-transparent">
      <nav class="navbar navbar-expand-lg bg-[--light] navbar-light py-1 px-4">
        <Link
          to="/"
          class="navbar-brand d-flex align-items-center text-center d-lg-none"
        >
          <img
            class="img-fluid w-[80px] object-contain"
            src="/logo.png"
            alt="Icon"
          />
        </Link>
        <Link to="/request" class="btn btn-primary px-3 d-none d-lg-flex">
          {t("menu-button")}
        </Link>
        <span
          onClick={() => (show2 ? setShow2(false) : setShow2(true))}
          className="flex cursor-pointer text-[14px] relative ms-[1rem]"
        >
          <span
            className="flex justify-center items-center gap-2"
            style={{ whiteSpace: "nowrap", fontSize: "14px" }}
          >
            {i18n.language == "en" && <Flag country="US" />}
            {i18n.language == "fa" && <Flag country="IR" />}
            {i18n.language == "ar" && <Flag country="SA" />}{" "}
            {i18n.language == "en" && "English - EN"}
            {i18n.language == "fa" && "FA - فارسی "}
            {i18n.language == "ar" && "AR - العربیه"}
            <FaAngleDown className="" size={15} />
          </span>

          {show2 && (
            <span className={`drop_down h-fit`}>
              <span
                onClick={() => handleChangeLanguge("en")}
                className=" cursor-pointer flex items-center gap-2 w-full  py-2 px-3 whitespace-nowrap"
              >
                <Flag country="US" />
                <span> EN - English </span>
              </span>
              <span
                onClick={() => handleChangeLanguge("fa")}
                className=" cursor-pointer flex items-center gap-2 w-full py-2 px-3 whitespace-nowrap "
              >
                <Flag country="IR" />
                <span> FA - فارسی </span>
              </span>
              <span
                onClick={() => handleChangeLanguge("ar")}
                className=" cursor-pointer flex items-center gap-2 w-full py-2 px-3 whitespace-nowrap "
              >
                <Flag country="SA" />
                <span>AR - العربیه</span>
              </span>
            </span>
          )}
        </span>
        <button
          type="button"
          class="navbar-toggler"
          onClick={() => setShow(!show)}
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class={`${
            !show ? "max-h-0" : "max-h-52"
          } lg:max-h-52 duration-500 navbar-collapse overflow-hidden `}
          id="navbarCollapse"
        >
          <div  class="navbar-nav flex-col flex ltr:items-start rtl:items-end ml-auto rtl:lg:flex-row-reverse ltr:lg:flex-row">
            <Link
              to="/"
              class={`nav-item nav-link ${
                location.pathname === "/" ? "active" : ""
              }`}
            >
              {t("menu-home")}
            </Link>
            <Link
              to="/map"
              class={`nav-item nav-link ${
                location.pathname === "/map" ? "active" : ""
              }`}
            >
              {t("menu-map")}
            </Link>
            <Link
              to="/about"
              class={`nav-item nav-link ${
                location.pathname === "/about" ? "active" : ""
              }`}
            >
              {t("menu-about")}
            </Link>
            <Link
              to="/contact"
              class={`nav-item nav-link ${
                location.pathname === "/contact" ? "active" : ""
              }`}
            >
              {t("menu-contact")}
            </Link>
          </div>
          <Link
            to="/"
            class="navbar-brand d-lg-flex items-center justify-end text-center d-none"
          >
            <img
              class="img-fluid w-[80px] object-contain"
              src="/logo.png"
              alt="Icon"
            />
          </Link>
        </div>
      </nav>
    </div>
  );
};

export default Header;
