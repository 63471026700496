import React from "react";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Slider = () => {
  const [t] = useTranslation("global");
  return (
    <>
      <div class="container-fluid header  p-0">
        <div class="row g-0 px-[24px] align-items-center flex-column-reverse flex-md-row">
          <div style={{backdropFilter : "blur(10px)"}} class="col-md-6 p-[1.5rem] lg:p-10 mt-[30px]  text-center bg-white rounded-[8px] shadow-sm">
            <h1
              data-aos="fade-left"
              data-aos-delay="200"
              class="display-5  mb-4 sm:text-center ltr:lg:text-left rtl:lg:text-right"
              dangerouslySetInnerHTML={{ __html: t("slider-title") }}
            />
            <p
              data-aos="fade-left "
              data-aos-delay="300"
              class=" mb-4 pb-2 sm:text-center ltr:lg:text-left rtl:lg:text-right"
            >
              {t("slider-desc")}
            </p>
            <div className="flex justify-center lg:justify-start">
              <Link
                data-aos="fade-left"
                data-aos-delay="400"
                to="/request"
                class="btn btn-primary py-3 px-5 w-fit"
              >
                {t("menu-button")}
              </Link>
            </div>
          </div>
          <div
            class="col-md-6 relative"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <span className="owl-nav rtl:lg:right-[25px]  rtl:right-[15px] ltr:left-[15px] ltr:lg:left-[25px] z-20">
              <span className="owl-prev">
                <FaAngleRight color="#fff" />
              </span>
              <span className="owl-next">
                <FaAngleLeft color="#fff" />
              </span>
            </span>
            <Swiper
              modules={[Navigation, Autoplay]}
              autoplay={{ delay: 3000 }}
              loop
              dir="ltr"
              navigation={{ nextEl: ".owl-next", prevEl: ".owl-prev" }}
            >
              <SwiperSlide>
                <div className="lg:mt-[65px] p-[3vw]">
                  <span className="lg:w-[400px] w-[230px] rotate-12 top-2/3 -translate-y-1/2 left-1/2 -translate-x-1/2 h-[230px] lg:h-[400px] bg-[--light] rounded-[15%] absolute -z-10"></span>

                  <img
                    class="object-contain w-full h-full "
                    src="/products/1 (1).png"
                    alt=""
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lg:mt-[65px] p-[3vw]">
                  <span className="lg:w-[400px] w-[230px] rotate-12 top-2/3 -translate-y-1/2 left-1/2 -translate-x-1/2 h-[230px] lg:h-[400px] bg-[--light] rounded-[15%] absolute -z-10"></span>

                  <img
                    class="object-contain w-full h-full "
                    src="/products/1 (2).png"
                    alt=""
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lg:mt-[65px] p-[3vw]">
                  <span className="lg:w-[400px] w-[230px] rotate-12 top-2/3 -translate-y-1/2 left-1/2 -translate-x-1/2 h-[230px] lg:h-[400px] bg-[--light] rounded-[15%] absolute -z-10"></span>

                  <img
                    class="object-contain w-full h-full "
                    src="/products/1 (3).png"
                    alt=""
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default Slider;
