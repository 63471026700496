import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const clients = [
  "/client (1).jpg",
  "/client (2).jpg",
  "/client (3).jpg",
  "/client (4).jpg",
  "/client (5).jpg",
  "/client (6).jpg",
  "/client (1).png",
];

const About = ({ home }) => {
  const [t] = useTranslation("global");
  return (
    <>
      <div class="container-xxl py-5 overflow-x-hidden">
        <div class="container">
          <div class="row g-5 align-items-center lg:px-10">
            <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div
                data-aos="fade-right"
                data-aos-delay="200"
                class=" position-relative overflow-hidden "
              >
                <img class=" w-100 rounded-[8px] shadow" src="/about.jpg" />
              </div>
            </div>
            <div
              data-aos="fade-left"
              data-aos-delay="400"
              class="col-lg-6 wow fadeIn"
              data-wow-delay="0.5s"
            >
              <h2
                class="mb-3 display-5 text-center"
              >
                {t("about-title-parent")}
              </h2>
              <div
                className="text-center"
                dangerouslySetInnerHTML={{ __html: t("owner") }}
              />

              <h2
                style={{ textShadow: "2px 2px #fdb813" }}
                class="mb-3 font-bold text-[26px] text-center"
              >
                {t("about-title")}
              </h2>
              <div
                class="leading-7"
                dangerouslySetInnerHTML={{ __html: t("about-desc") }}
              />
              <div
                className="mt-3 lg:text-[18px] flex flex-wrap  gap-2"
                dangerouslySetInnerHTML={{ __html: t("about-list") }}
              />
              {home && (
                <Link class="btn btn-primary py-3 px-5 mt-3" to="/about">
                  {t("about-btn")}
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
