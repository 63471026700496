import Gallery from "components/common/Gallery";
import Footer from "components/Footer";
import Header from "components/header";
import PathShow from "components/PathShow";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

const Request = () => {
  const [t] = useTranslation("global");

  return (
    <>
      <div class="container-xxl  p-0 rtl:font-[Yekan] ltr:font-[Inter] relative z-10">
        <Header />
        <PathShow url="menu-button" image="/location (1).jpg" />

        <div class=" py-5">
          <div class="">
            <div
              class="text-center mx-auto "
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <h1 class="mb-3 text-[32px] font-bold">فرم درخواست</h1>
              <div className="mx-auto max-w-[700px]">
                <form className=" px-3">
                  <div class="row g-3">
                    <div class="col-md-6">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control "
                          id="name"
                          placeholder={t("form-name")}
                        />
                        <label for="name">{t("form-name")}</label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-floating">
                        <input
                          type="email"
                          class="form-control "
                          id="email"
                          placeholder={t("form-phone")}
                        />
                        <label for="email">{t("form-phone")}</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-floating">
                        <textarea
                          class="form-control "
                          placeholder="Leave a message here"
                          id="message"
                          style={{ height: "150px" }}
                        ></textarea>
                        <label for="message"> {t("form-text")}</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <button class="btn btn-primary w-100 py-3" type="submit">
                        {t("submit")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Gallery />

        {/* // form section // */}

        <Footer />
      </div>
    </>
  );
};

export default Request;
