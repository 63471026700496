import React from "react";
import { useTranslation } from "react-i18next";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const Gallery = () => {
  const [t] = useTranslation("global");
  return (
    <div class=" py-5">
      <div class="">
        <div
          class="text-center mx-auto mb-3 "
          data-aos="zoom-out"
          data-aos-delay="200"
        >
          <h3 class="mb-3 text-[32px] font-bold">{t("gallery")}</h3>
          <Swiper
            modules={[Autoplay]}
            autoplay={{ delay: 3000 }}
            dir="ltr"
            spaceBetween={20}
            loop
            breakpoints={{
              550: { slidesPerView: 1 },
              720: { slidesPerView: 2 },
              1024: { slidesPerView: 2 },
            }}
          >
            <SwiperSlide>
              <div
                class="cat-item d-block bg-light text-center rounded p-3"
                href=""
                data-aos="fade-bottom"
                data-aos-delay="200"
              >
                <div class="rounded p-1">
                  <img
                    class="img-fluid rounded"
                    src="/location (1).jpg"
                    alt="Icon"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                class="cat-item d-block bg-light text-center rounded p-3"
                href=""
                data-aos="fade-bottom"
                data-aos-delay="200"
              >
                <div class="rounded p-1">
                  <img
                    class="img-fluid rounded"
                    src="/location (2).jpg"
                    alt="Icon"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                class="cat-item d-block bg-light text-center rounded p-3"
                href=""
                data-aos="fade-bottom"
                data-aos-delay="200"
              >
                <div class="rounded p-1">
                  <img
                    class="img-fluid rounded"
                    src="/location (3).jpg"
                    alt="Icon"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                class="cat-item d-block bg-light text-center rounded p-3"
                href=""
                data-aos="fade-bottom"
                data-aos-delay="200"
              >
                <div class="rounded p-1">
                  <img
                    class="img-fluid rounded"
                    src="/location (4).jpg"
                    alt="Icon"
                  />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
