import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PathShow = ({ url, image }) => {
  const [t] = useTranslation("global");
  return (
    <div dir="rtl" class="container-fluid header  p-0">
      <div class="row g-0 align-items-center flex-column-reverse flex-md-row">
        <div class="col-md-6 p-5 mt-lg-5">
          <h1 class="display-5 animated fadeIn mb-4"> {t(url)}</h1>
          <nav aria-label="breadcrumb animated fadeIn">
            <ol class="breadcrumb text-uppercase">
              <li class="breadcrumb-item">
                <Link to="/">{t("menu-home")}</Link>
              </li>

              <li class="breadcrumb-item text-body active" aria-current="page">
                {t(url)}
              </li>
            </ol>
          </nav>
        </div>
        <div class="col-md-6 animated fadeIn">
          <img class="img-fluid h-[70vh] object-cover" src={image} alt="" />
        </div>
      </div>
      <div className="h-[30px] bg-[--secondary] w-full opacity-80"></div>
    </div>
  );
};

export default PathShow;
