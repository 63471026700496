import ProjectCard from "components/common/ProjectCard";
import Footer from "components/Footer";
import Header from "components/header";
import { Example_data } from "data/example";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

const ProjectsCompanyPage = () => {
  const [data, setData] = useState([]);

  const { name } = useParams();

  useEffect(() => {
    const filtered = Example_data.filter((item) => item.company == name);
    setData(filtered);
  }, [name]);
  return (
    <>
      <Header />
      <div className="main">
        <div class="page-title dark-background bg-dark">
          <div class="container">
            <h1>پروژه های {name}</h1>
            <nav class="breadcrumbs">
              <ol>
                <li>
                  <Link to={"/"}>خانه</Link>
                </li>
                <li>
                  <Link to={"/projects"}>پروژه ها</Link>
                </li>
                <li class="current">{name}</li>
              </ol>
            </nav>
          </div>
        </div>
        <section id="service-details" class="service-details section">
          <div class="container grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 lg:grid-cols-4 gap-3 lg:gap-4">
            {data.map((item) => (
              <ProjectCard data={item} />
            ))}
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default ProjectsCompanyPage;
