import React from "react";
import { useTranslation } from "react-i18next";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const Services = () => {
  const [t] = useTranslation("global");

  return (
    <div class="container-xxl py-5 overflow-x-hidden">
      <div class="container">
        <div
          class="text-center mx-auto mb-5 wow fadeInUp max-w-[600px]"
          data-aos="zoom-out"
          data-aos-delay="200"
        >
          <h1 class="mb-3 text-[32px] font-bold">{t("products-title")}</h1>
          <p>{t("products-desc")}</p>
        </div>
        <div class="row g-4">
          <Swiper
            dir="ltr"
            modules={[Autoplay]}
            autoplay={{ delay: 3000 }}
            spaceBetween={20}
            loop
            breakpoints={{
              720: { slidesPerView: 2 },
              1024: { slidesPerView: 3 },
            }}
            className="py-3"
          >
            <SwiperSlide>
              <div
                class="cat-item d-block bg-light text-center rounded p-3 shadow"
                href=""
                data-aos="fade-bottom"
                data-aos-delay="200"
              >
                <div class="rounded p-1">
                  <img
                    class="img-fluid rounded"
                    src="/products/slider (1).jpg"
                    alt="Icon"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                class="cat-item d-block bg-light text-center rounded p-3 shadow"
                href=""
                data-aos="fade-bottom"
                data-aos-delay="200"
              >
                <div class="rounded p-1">
                  <img
                    class="img-fluid rounded"
                    src="/products/slider (2).jpg"
                    alt="Icon"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                class="cat-item d-block bg-light text-center rounded p-3 shadow"
                href=""
                data-aos="fade-bottom"
                data-aos-delay="200"
              >
                <div class="rounded p-1">
                  <img
                    class="img-fluid rounded"
                    src="/products/slider (3).jpg"
                    alt="Icon"
                  />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <Swiper
            dir="ltr"
            modules={[Autoplay]}
            autoplay={{ delay: 3000 }}
            spaceBetween={20}
            loop
            breakpoints={{
              550: { slidesPerView: 2 },
              720: { slidesPerView: 3 },
              1024: { slidesPerView: 4 },
            }}
               className="py-3"
          >
            <SwiperSlide>
              <div
                class="cat-item d-block bg-light text-center rounded p-3 shadow"
                href=""
                data-aos="fade-bottom"
                data-aos-delay="200"
              >
                <div class="rounded p-1">
                  <img
                    class="img-fluid rounded"
                    src="/products/slide2 (1).jpg"
                    alt="Icon"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                class="cat-item d-block bg-light text-center rounded p-3 shadow"
                href=""
                data-aos="fade-bottom"
                data-aos-delay="200"
              >
                <div class="rounded p-1">
                  <img
                    class="img-fluid rounded"
                    src="/products/slide2 (2).jpg"
                    alt="Icon"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                class="cat-item d-block bg-light text-center rounded p-3 shadow"
                href=""
                data-aos="fade-bottom"
                data-aos-delay="200"
              >
                <div class="rounded p-1">
                  <img
                    class="img-fluid rounded"
                    src="/products/slide2 (3).jpg"
                    alt="Icon"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                class="cat-item d-block bg-light text-center rounded p-3 shadow"
                href=""
                data-aos="fade-bottom"
                data-aos-delay="200"
              >
                <div class="rounded p-1">
                  <img
                    class="img-fluid rounded"
                    src="/products/slide2 (4).jpg"
                    alt="Icon"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                class="cat-item d-block bg-light text-center rounded p-3 shadow"
                href=""
                data-aos="fade-bottom"
                data-aos-delay="200"
              >
                <div class="rounded p-1">
                  <img
                    class="img-fluid rounded"
                    src="/products/slide2 (5).jpg"
                    alt="Icon"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                class="cat-item d-block bg-light text-center rounded p-3 shadow"
                href=""
                data-aos="fade-bottom"
                data-aos-delay="200"
              >
                <div class="rounded p-1">
                  <img
                    class="img-fluid rounded"
                    src="/products/slide2 (6).jpg"
                    alt="Icon"
                  />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Services;
