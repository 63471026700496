import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  const [t] = useTranslation("global");

  return (
    <>
      <div
        dir="rtl"
        className="container-fluid bg-dark text-white-50 footer pt-4  wow fadeIn "
        data-wow-delay="0.1s"
      >
        <div className="pattern_ab"></div>
        <div className="container py-4 z-10">
          <div className="row g-5 justify-content-center px-lg-4">
            <div id="contact" className="col-lg-6 col-md-6">
              <h5 className="text-white mb-4 ">{t("menu-contact")}</h5>
              <a
                href={"https://wa.me/989377547384"}
                dir="ltr"
                target="_blank"
                className="text-end text-white"
                rel="noopener"
              >
                0937 754 7384
                <i className="fab fa-whatsapp ms-2 fs-4"></i>
                <br />
              </a>
              <div className="d-flex pt-4">
                <a
                  className="btn btn-outline-light fs-5 btn-social"
                >
                  <i className="fab fa-telegram"></i>
                </a>
                <a
                  className="btn btn-outline-light fs-5 btn-social"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <h5 className="text-white mb-4 ">{t("footer-main-link")}</h5>
              <Link className="btn btn-link text-white-50 text-end " to="/">
                {t("menu-home")}
              </Link>
              <Link className="btn btn-link text-white-50 text-end " to="/map">
                {t("menu-map")}
              </Link>
              <Link
                className="btn btn-link text-white-50 text-end "
                to="/about"
              >
                {t("menu-about")}
              </Link>
              <Link
                to="/contact"
                className="btn btn-link text-white-50 text-end "
              >
                {t("menu-contact")}
              </Link>
            </div>
          </div>
        </div>
        <div className="container z-10">
          <div className="copyright">
            <div className="row">
              <div className="col-12 text-center mb-3 mb-md-0">
                {t("copy-right")}
              </div>
            </div>
          </div>
        </div>
      </div>

      <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top">
        <i className="bi bi-arrow-up"></i>
      </a>
    </>
  );
};

export default Footer;
