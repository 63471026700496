import About from "components/About";
import Gallery from "components/common/Gallery";
import Footer from "components/Footer";
import Header from "components/header";
import PathShow from "components/PathShow";
import { useTranslation } from "react-i18next";

const AboutPage = () => {
  const [t] = useTranslation("global");

  return (
    <>
      <div class="container-xxl  p-0 rtl:font-[Yekan] ltr:font-[Inter] relative z-10">
        <Header />
        <PathShow url="menu-about" image="/location (2).jpg" />
        <div className="md:grid-cols-2 grid py-5 px-[1rem] lg:px-20 items-center gap-3">
          <div className="">
            <div
              class="text-center mx-auto mb-3 "
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              <h3 class="mb-3 text-[32px] font-bold">{t("brand")}</h3>
            </div>
            <div className=" leading-8 text-[16px] px-4" dangerouslySetInnerHTML={{__html : t("about-desc-2")}}/>
          </div>
          <div
            data-aos="fade-right"
            data-aos-delay="200"
            class="about-img position-relative overflow-hidden p-3 p-lg-4 pe-0 rounded-[20px] max-w-[450px] rtl:lg:mr-auto ltr:lg:ms-auto"
          >
            <video controls class="img-fluid w-full z-10 relative rounded-[15px] shadow" src="/video.mp4" />
          </div>
        </div>

        <Gallery />
        <About />
        <Footer />
      </div>
    </>
  );
};

export default AboutPage;
