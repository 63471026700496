import About from "components/About";
import Gallery from "components/common/Gallery";
import Footer from "components/Footer";
import Header from "components/header";
import PathShow from "components/PathShow";
import { useTranslation } from "react-i18next";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useRef, useState } from "react";
import {
  FaCircle,
  FaLocationDot,
  FaLocationPin,
  FaMapLocation,
  FaTimeline,
} from "react-icons/fa6";
import { RxLapTimer } from "react-icons/rx";
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png",
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
});

const center = [32.6895, 51.389]; // مختصات مرکز نقشه

const MapPage = () => {
  const [t] = useTranslation("global");
  const [markers, setMarkers] = useState([
    {
      position: [36.5640711, 53.1032771],
      label: `شعبه 1 ساری`,
      name: `بلوار امام رضا , بازار رضا`,
    },
    {
      position: [36.5647161, 53.0639135],
      label: `شعبه 2 ساری`,
      name: `خیابان قارن نبش قارن 16`,
    },
    {
      position: [35.722289, 51.492278],
      label: `شعبه 3 نارمک`,
      name: `ابتدای خیابان آیت به سمت میدان هفت حوض , قبل از خیابان دلاور`,
    },
    {
      position: [32.616161, 51.670076],
      label: `شعبه 4 اصفهان`,
      name: `شیخ صدوق جنوبی , ضلع شرقی برج`,
    },
    {
      position: [29.627935, 52.507787],
      label: `شعبه 5 شیراز`,
      name: `خیابان عفیف آباد, جنب بیمارستان میر`,
    },
    {
      position: [32.24624, 54.028075],
      label: `شعبه 6 یزد`,
      name: `صفاییه, میدان مادر`,
    },
    {
      position: [35.475586, 51.695298],
      label: `شعبه 7 پاکدشت`,
      name: `شهرک دانشگاه خیابان مدرس 2, روبه روی خیابان سازمان تبلیغات جنب کوچه دانش اول`,
    },
    {
      position: [30.6535984, 51.6104002],
      label: `شعبه 8 یاسوج`,
      name: `خیابان ستارخان`,
    },
    {
      position: [35.75891, 51.540194],
      label: `شعبه 9 تهرانپارس`,
      name: `خیابان توحید , نبش کوچه 15`,
    },
    {
      label: `شعبه 10 شیراز`,
      name: `در حال راه اندازی`,
    },
    {
      position: [31.34513, 48.675341],
      label: `شعبه 12 اهواز`,
      name: `کیانپارس خیابان 13 شرقی`,
    },
    {
      position: [35.692699, 51.487649],
      label: `شعبه 14 پیروزی`,
      name: `روبه روی پمپ بنزین مقداد, بین خیابان چهارم و پنجم نیرو هوایی`,
    },
    {
      position: [35.703837, 51.390884],
      label: `شعبه 15 کارگر شمالی`,
      name: `خیابان کارگر نبش امین`,
    },
    {
      label: `شعبه 17 اهواز`,
      name: `در حال راه اندازی`,
    },
    {
      position: [35.63664, 51.407342],
      label: `شعبه 18 نازی آباد`,
      name: `خیابان مداین, بین خدابنده و رنجبر`,
    },
    {
      label: `شعبه 19 تهران`,
      name: `در حال راه اندازی`,
    },
    {
      position: [35.757845, 51.47881],
      label: `شعبه 20 ملت`,
      name: `بالاتر از میدان ملت,نبش صالحی`,
    },
    {
      position: [35.703345, 51.427417],
      label: `شعبه 21 نهاوند`,
      name: `خیابان خاقانی`,
    },
    {
      label: `شعبه 21 تهرانسر`,
      name: `در حال راه اندازی`,
    },
    {
      label: `شعبه 23 تهران`,
      name: `در حال راه اندازی`,
    },
  ]);

  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);

  const handleViewOnMap = (lat, lng) => {
    if (mapRef.current) {
      mapContainerRef.current.scrollIntoView();
      mapRef.current.flyTo([lat, lng], 15, {
        duration: 1,
      });
    }
  };

  return (
    <>
      <div class="container-xxl  p-0 rtl:font-[Yekan] ltr:font-[Inter] relative z-10">
        <Header />
        <PathShow url="menu-map" image="/location (1).jpg" />
        <div
          ref={mapContainerRef}
          className="py-5 px-[1rem] items-center gap-3"
        >
          <MapContainer
            ref={mapRef}
            center={center}
            zoom={6}
            className="w-full h-[350px] md:h-[600px]"
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {markers.map((marker, index) => {
              if (marker.position) {
                return (
                  <Marker key={index} position={marker.position}>
                    <Popup>{marker.label}</Popup>
                  </Marker>
                );
              }
            })}
          </MapContainer>
        </div>
        <div className="grid md:grid-cols-2 pb-8 gap-3 px-[12px]">
          {markers.map((marker, index) => {
            return (
              <div className="w-full border-b p-3 bg-white rounded-[8px] shadow-sm">
                <div className="flex gap-2 items-center">
                  <FaCircle size={10} color="#fdb813" />
                  <span className="text-[18px] text-black font-bold">
                    {marker.label}
                  </span>
                </div>
                <div className="flex gap-2 items-center">
                  {marker.position ? (
                    <FaLocationDot size={15} color="#fdb813" />
                  ) : (
                    <RxLapTimer size={15} color="red" />
                  )}
                  <span className="text-[16px] ">{marker.name}</span>
                </div>
                {marker.position && (
                  <button
                    onClick={() =>
                      handleViewOnMap(marker.position[0], marker.position[1])
                    }
                    className="btn text-blue-600 "
                  >
                    مشاهده در نقشه >>>
                  </button>
                )}
              </div>
            );
          })}
        </div>

        <div></div>
        <Footer />
      </div>
    </>
  );
};

export default MapPage;
