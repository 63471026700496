import Home from "pages/Home";
import "./App.css";
import {
  createBrowserRouter,
  Link,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import ProjectsPage from "pages/ProjectsPage";
import ProjectsCompanyPage from "pages/ProjectsCompanyPage";
import { useEffect } from "react";
import { FaPhone, FaWhatsapp } from "react-icons/fa6";
import ProjectDetailPage from "pages/ProjectDetailPage";
import ScrollToTop from "components/ScrooltoTop";
import AboutPage from "pages/AboutPage";
import Request from "pages/Request";
import i18next from "i18next";
import global_en from "./translation/en/global.json";
import global_fa from "./translation/fa/global.json";
import global_ar from "./translation/ar/global.json";
import { I18nextProvider, useTranslation } from "react-i18next";
import MapPage from "pages/MapPage";
import ContactPage from "pages/ContactPage";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: { global: global_en },
    fa: { global: global_fa },
    ar: { global: global_ar },
  },
});

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <ScrollToTop />
          <Home />
        </>
      ),
    },
    {
      path: "/about",
      element: (
        <>
          <ScrollToTop />
          <AboutPage />
        </>
      ),
    },
    {
      path: "/request",
      element: (
        <>
          <ScrollToTop />
          <Request />
        </>
      ),
    },
    {
      path: "/map",
      element: (
        <>
          <ScrollToTop />
          <MapPage />
        </>
      ),
    },
    {
      path: "/contact",
      element: (
        <>
          <ScrollToTop />
          <ContactPage />
        </>
      ),
    },
    {
      path: "/projects/:name",
      element: (
        <>
          <Link
            to={"https://wa.me/+971508856571"}
            className="fixed right-4 bottom-4 bg-green-500 gap-1  rounded-full flex p-[10px] z-50 text-white items-center "
          >
            <FaWhatsapp size={30} /> <span className="mt-1">واتساپ</span>
          </Link>
          <Link
            to={"tel:+971508856571"}
            className="fixed right-4 bottom-[4.5rem] bg-slate-800 gap-1  rounded-full flex p-[10px] z-50 text-white items-center "
          >
            <FaPhone size={22} /> <span className="mt-1">تماس</span>
          </Link>
          <ScrollToTop />
          <ProjectsCompanyPage />
        </>
      ),
    },
    {
      path: "/projects/:company/:name",
      element: (
        <>
          <Link
            to={"https://wa.me/+971508856571"}
            className="fixed right-4 bottom-4 bg-green-500 gap-1  rounded-full flex p-[10px] z-50 text-white items-center "
          >
            <FaWhatsapp size={30} /> <span className="mt-1">واتساپ</span>
          </Link>
          <Link
            to={"tel:+971508856571"}
            className="fixed right-4 bottom-[4.5rem] bg-slate-800 gap-1  rounded-full flex p-[10px] z-50 text-white items-center "
          >
            <FaPhone size={22} /> <span className="mt-1">تماس</span>
          </Link>
          <ScrollToTop />
          <ProjectDetailPage />
        </>
      ),
    },
  ]);

  return (
    <>
      <I18nextProvider i18n={i18next}>
        <RouterProvider router={router} />
      </I18nextProvider>
    </>
  );
}

export default App;
